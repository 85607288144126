.container {
  padding: 1px;
  background-color: "#494848";
  z-index: -2;
  position: relative;
}

.draw_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.sticky {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
}

.draw_options {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 315px;
  gap: 20px;
  left:0;
  position: fixed;
  height: 100px;
  cursor: pointer;

}

.draw_button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.5rem;
  gap: 0.1rem;
  margin: 0.1rem;
  background-color: #ffffff;
  outline: 1px solid #808080;
  height: 100vh;
  box-shadow: 3px 4px 4px #d3d3d3;
}

.icon_button {
  margin: 10px;
  cursor: pointer;
  color: #000000;
  padding: 2px;
  transition: background-color 0.3s;
}

.icon_button:hover {
  background-color: #d8d7d8;
  border-radius: 4px;
}

.icon_button_icon1 {
  background-color: #d8d7d8;
  border-radius: 4px;
}

.image_upload_popup {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 4px #494848;
  z-index: 1;
}

.image_upload_popup_content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px #6f7c80;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  margin-bottom: 10px;
}

.image_upload_popup h3 {
  margin-bottom: 10px;
}

.image_upload_button {
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  margin-right: 10px;
  background-color: #d8d7d8;
  cursor: pointer;
}

.image_upload_button_cancel {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid;
  font-weight: bold;
  cursor: pointer;
}

.input_button {
  display: none;
}

.fullscreen_main_container {
  border-radius: 4px;
  outline: 1px solid #494848;
  padding: 8px;
  position: fixed;
  bottom: 10px;
  right: 120px;
  z-index: 2;
  width: 68px;
  height: 35px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px #494848;
}

.fullscreen_container {
  position: fixed;
  bottom: 10px;
  right: 120px;
  color: #000000;
  padding: 6px;
  border-radius: 4px;
  z-index: 1;
}

.nav_container {
  position: fixed;
  bottom: 10px;
  right: 155px;
  color: white;
  padding: 6px;
  border-radius: 4px;
  z-index: 1;
}
