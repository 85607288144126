
.outer_div {
    display: flex !important;
    flex-wrap: wrap !important;
    /* justify-content: space-between; */
    width: 100%;
    margin-bottom: 10px;
}
.input_div {
    width: 80%;
    height: 40px;
}
.input_box {
    width: 80%;
    height: 40px;
    border-radius: 5px;
}

.button_div {
    align-items: center;
    background-color: #0362A1;

    color: white;
    width: 18%;
    border:none;
    border-radius: 5px;
}
.share_btn {
    background-color: #0362A1;
    width:100%;
    height: 40px;
    color: white;
    border-radius: 3px;
}
.usercolor_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 30px;
    border-radius: 100%;
    color: white;
}


.dropdown_card {
    width: 310px;
    max-height: 250px;
}

.username_span {
    width: 350px;
    margin-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.user_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;

}

.user_name {

    margin-left: 15px;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
}



