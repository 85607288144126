.color_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.color_option {
  background-color: var(--color);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: 4px;
  cursor: pointer;
  border: none;
}

.tool_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.progress_bar_container {
  position: relative;
  width: 300px; /* Adjust the width as needed */
  height: 5px; /* Adjust the height as needed */
  background-color: #e0e0e0; 
  margin-bottom: 10px;

}

.progressBar {
  height: 100%;
  background-color: #3498db; /* Color of the progress bar */
  transition: width 0.3s; /* Smooth transition for the width change */
}
/* Other styles... */

.tool_option {
  color: var(--color);
  font-weight: bold;
  padding-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tool_icon {
  cursor: pointer;
  color: var(--color);
  padding: 2px;
  transition: background-color 0.3s;
}

.highlighted_icon {
  background-color: #d8d7d8;
  border-radius: 4px;
}

.eraser_option {
  font-weight: bold;
  padding-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selected_icon_color {
  border: 2px solid #6f7c80;
}
