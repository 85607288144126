.memberIcon {
  min-width: 44px;
  height: 44px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.shareBtn {
  max-width: 400px;
  overflow: hidden;
  padding-right: 8px;
  text-decoration: none;
  height: 30px;
  background-color: #dfdcdc;
  color: rgb(42, 41, 41);
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.shareBtn:hover {
  background-color: white;
  color: black;
}

.share_heading {
  margin-bottom: -35px;
  margin-left: 8px;
  border-bottom: 0px;
}

.bgHoverColor {
  width: 250px;
}

.leaveBtn {
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 6px 12px;
  background-color: #b04632;
  box-shadow: none;
  border: none;
  width: 100%;
}

.nameCircleInnerName {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left;
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 10px;
  border-radius: 50%;
  margin-top: 5px;
  font-weight: 600;
  padding: 16px;
}

.board_label {
  padding: 6px 40px !important;
}

.shareCrossIcon {
  cursor: pointer;
}

@media screen and (max-width: 490px) {
  .memberIcon {
    width: 55px;
    height: 55px;
  }
  .share_name_modal {
    padding: 0 !important;
  }
  .share_email_modal {
    padding: 0 !important;
    font-size: 0.8rem;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
  }
  .span_div {
    padding: 0 !important;
  }
}
