.container {
  display: flex;
  justify-content: space-between;
}

.noteContainer {
  display: flex;
  width: 170px;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;
}

.header {
  display: flex;
  gap: 30px;
  margin: 10px;
}

.shapeOption {
  border-bottom: none;
  padding-bottom: 5px;
  cursor: pointer;
}

.selectedShape {
  border-bottom: 3px solid green;
}
