.popover_style {
  background: #ffffff;
  padding: 5px;
  margin: 5px;
}

.list_style {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem_style {
  padding: 5px;
  margin: 5px;
  font-weight: bold;
  cursor: pointer;
  background-color: #6f7c80;
  color: #ffffff;
  border-radius: 5px;
}
