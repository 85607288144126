* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.link_css {
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}
.link_css:hover {
  text-decoration: none;
}
.custom_link {
  text-decoration: none;
  color: #070707;
  list-style: none;
  display: block;
  font-family: Roboto;
  font-size: 0px;
  line-height: 19px;
  text-align: left;
}
.custom_link:hover {
  text-decoration: none;
}
/* navbar section css*/
.driver_nav {
  position: fixed;
}
.drive_navbar {
  background-color: #ffff;
  padding: 0px 20px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  border-bottom: 1px solid #CED4DA;
}
.logo_image {
  width: 100%;
  height: 50px;
  background-color: #ffff;
  padding: 5px;
}
.drive_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section_of_img {
  display: flex;
  align-items: center;
  gap: 110px;
}
.hum_berger {
  width: 23px;
  height: 23px;
  margin: auto;
  display: none;
}
.user_details {
  display: flex;
  align-items: center;
  gap: 20px;
}
.user_name {
  margin-bottom: 0;
}
.image_icons {
  width: 23px;
  height: 23px;
  margin: auto;
}
.profile_div {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #DEE2E6;
  padding: 3px 5px;
}
.user_image_icons {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #CED4DA;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  background-color: var(--user-color);
}
.section_of_img p {
  margin-bottom: 0;
  font-weight: 600;
}
/* toggel section of products */
/* for grid icon css */
.hidden_arrow {
  border: none;
  background-color: transparent;
}
.hidden_arrow::after {
  display: none !important;
  border-top: 0.3em solid transparent !important;
}
.switch_to {
  border: none;
  box-shadow: 0 4px 16px rgba(17, 17, 26, .1), 0 8px 32px rgba(17, 17, 26, .05);
  margin-top: 10px !important;
  padding: 20px !important;
  width: 300px;
}
.dropdown_item {
  align-items: center !important;
  background-color: initial;
  border: 0;
  clear: both;
  color: #36315D;
  display: flex !important;
  font-size: 1.1rem;
  font-weight: 400;
  padding: 0.5rem 0 !important;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
.switch_dots {
  align-items: center;
  background: #D9D9D9;
  border-radius: 50%;
  display: flex;
  height: 32px;
  margin: auto 10px auto 0;
  width: 32px;
}
.switch_to h6 {
  font-size: 1.5rem;
  font-weight: 600;
}
@media only screen and (max-width:1200px) {
  .hum_berger {
    display: block;
  }
}
.nav_home {
  color: #070707;
}
@media only screen and (max-width:1000px) {
  .search_bar_comp {
    margin-top: 10px;
    display: none;
  }
}
/* mobile sidebar css */
.drawer_nav {
  background: #EBECED;
}
.mobile_view_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  border-bottom: 1px solid #ADB5BD;
  padding-bottom: 18px;
}
.mobile_sidebar {
  padding: 20px 20px 5px;
}
.mobile_sidebar_tab1 {
  padding-top: -10px;
  margin-bottom: 25px;
}
.mobile_side_contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 182px;
}
.mobile_sidebar_tab2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.mobile_sidebar_tab {
  background-color: hwb(0 100% 0%);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 172px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.mobile_sidebar_tab.active {
  background-color: #4A56E6;
  color: #ffff;
}
.mobile_sidebar_tab:hover {
  background-color: #4A56E6;
  color: #ffff;
}
.mobile_sidebar_tab_activity {
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 172px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile_sidebar_tab-activity:hover {
  background-color: #ffff;
  border-radius: 5px;
  list-style: none;
  text-decoration: none;
  color: #4A56E6;
}
.drawer_nav::-webkit-scrollbar {
  display: none;
}
.drawer_nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.side_nav::-webkit-scrollbar {
  display: none;
}
.side_nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}